import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import downloadIcon from "../assets/images/download-icon.svg"
import pdfIcon from "../assets/images/pdf-icon.svg"
import { productImages } from "../images/products/Products Pages/productImages"
import StandardLayout from "../layouts/standard"

export default function ProductPage({ pageContext }) {
  console.log(pageContext)

  return pageContext.info ? (
    <StandardLayout title="Contact Us">
      <div className="cover-cont">
        <img
          src={
            pageContext.image.startsWith("https")
              ? pageContext.image
              : productImages[pageContext.image].default
          }
          alt=""
        />
      </div>

      <div className="catalog-head">{pageContext.title}</div>

      <div className="uk-container uk-margin">
        <div className="product-info">{pageContext.info}</div>

        <div className="product-card-cont">
          {pageContext.products.map((product, i) => (
            <ProductCard key={`product-${i}`} product={product} />
          ))}
        </div>
      </div>
    </StandardLayout>
  ) : (
    <img src={productImages[pageContext?.image]?.default ?? null} alt="" />
  )
}

function ProductCard({ product }) {
  return (
    <div className="product-card">
      <div className="product-card-image">
        <img
          src={
            product.data.cover.startsWith("https")
              ? product.data.cover
              : productImages[product.data.cover].default
          }
          alt=""
        />
      </div>
      <div className="product-card-info">
        <div>{product.title}</div>
        <div>{product.data.info}</div>
        <div>
          <Link to={product.url} className="new-maroon-btn">
            Know More
          </Link>
        </div>
      </div>
    </div>
  )
}
