const CompactLaminates1 = require("./COMPACT LAMINATES/COMPACT LAMINATES.webp")
const CompactLaminates2 = require("./COMPACT LAMINATES/CUBOID - REST ROOM CUBICLES & LOCKERS.webp")
const CompactLaminates3 = require("./COMPACT LAMINATES/FASCIA - EXTERIOR CLADDING.webp")
const CompactLaminates4 = require("./COMPACT LAMINATES/INDUSTRIAL LAMINATES.webp")
const CompactCover1 = require("./COMPACT LAMINATES/Group 1951.png")
const CompactCover2 = require("./COMPACT LAMINATES/Group 1963.png")
const CompactCover3 = require("./COMPACT LAMINATES/Group 1967.png")
const CompactCover4 = require("./COMPACT LAMINATES/Group 1971.png")

const DecorativeLaminates1 = require("./Laminates -Decorative Category/Decorative Category.webp")
const DecorativeLaminates2 = require("./Laminates -Decorative Category/POST FORMING LAMINATES.webp")

const LuxceLaminates1 = require("./LUXCE LAMINATE/ANTI-FINGER PRINT PRELAM BOARD.webp")
const LuxceLaminates2 = require("./LUXCE LAMINATE/GLOSS PRO+ HIGH GLOSS LAMINATE.webp")
const LuxceLaminates3 = require("./LUXCE LAMINATE/LUXCE LAMINATE.webp")
const LuxceLaminates4 = require("./LUXCE LAMINATE/TOUCH ME - ANTIFINGER PRINT LAMINATE.webp")
const LuxceCover1 = require("./LUXCE LAMINATE/Group 1716.png")
const LuxceCover2 = require("./LUXCE LAMINATE/Group 1728.png")
const LuxceCover3 = require("./LUXCE LAMINATE/Group 1732.png")
const LuxceCover4 = require("./LUXCE LAMINATE/Group 1736.png")

const PerformanceLaminates1 = require("./PERFORMANCE LAMINATES/CHEMICAL RESISTANT LAMINATES.webp")
const PerformanceLaminates2 = require("./PERFORMANCE LAMINATES/ELECTROSTATIC DISSIPATIVE LAMINATES.webp")
const PerformanceLaminates3 = require("./PERFORMANCE LAMINATES/FIRE RETARDANT GRADE LAMINATES.webp")
const PerformanceLaminates4 = require("./PERFORMANCE LAMINATES/PERFORMANCE LAMINATES.webp")

const SolidSurface1 = require("./SOLID SURFACES/ACRYLIC SOLID SURFACE - GRANEX.webp")
const SolidSurface2 = require("./SOLID SURFACES/MODIFIED SOLID SURFACE - MARVELLA.webp")
const SolidSurface3 = require("./SOLID SURFACES/SOLID SURFACES.webp")
const SolidSurfaceCover1 = require("./SOLID SURFACES/Group 1292.png")
const SolidSurfaceCover2 = require("./SOLID SURFACES/Group 1304.png")
const SolidSurfaceCover3 = require("./SOLID SURFACES/Group 1300.png")

const SpecialLaminates1 = require("./Special Lamination Category/CHALK BOARD.webp")
const SpecialLaminates2 = require("./Special Lamination Category/DIGITAL LAMINATES.webp")
const SpecialLaminates3 = require("./Special Lamination Category/FLICKER LAMINATE.webp")
const SpecialLaminates4 = require("./Special Lamination Category/MAGNETIC LAMINATE.webp")
const SpecialLaminates5 = require("./Special Lamination Category/MARKER BOARD.webp")
const SpecialLaminates6 = require("./Special Lamination Category/METALLIC LAMINATE.webp")
const SpecialLaminates7 = require("./Special Lamination Category/MIRROR SHIELD LAMINATE.webp")
const SpecialLaminates8 = require("./Special Lamination Category/Special Lamination Category.webp")
const SpecialLaminates9 = require("./Special Lamination Category/SYNCHRO LAMINATES.webp")
const SpecialLaminates10 = require("./Special Lamination Category/UNICORE LAMINATE.webp")
const SpecialCover1 = require("./Special Lamination Category/Group 601.png")
const SpecialCover2 = require("./Special Lamination Category/Group 614.png")
const SpecialCover3 = require("./Special Lamination Category/Group 622.png")
const SpecialCover4 = require("./Special Lamination Category/Group 626.png")
const SpecialCover5 = require("./Special Lamination Category/Group 630.png")
const SpecialCover6 = require("./Special Lamination Category/Group 634.png")
const SpecialCover7 = require("./Special Lamination Category/Group 638.png")
const SpecialCover8 = require("./Special Lamination Category/Group 642.png")
const SpecialCover9 = require("./Special Lamination Category/Group 646.png")
const SpecialCover10 = require("./Special Lamination Category/Group 650.png")

const productImages = {
  CompactLaminates1,
  CompactLaminates2,
  CompactLaminates3,
  CompactLaminates4,
  DecorativeLaminates1,
  DecorativeLaminates2,
  LuxceLaminates1,
  LuxceLaminates2,
  LuxceLaminates3,
  LuxceLaminates4,
  PerformanceLaminates1,
  PerformanceLaminates2,
  PerformanceLaminates3,
  PerformanceLaminates4,
  SolidSurface1,
  SolidSurface2,
  SolidSurface3,
  SpecialLaminates1,
  SpecialLaminates2,
  SpecialLaminates3,
  SpecialLaminates4,
  SpecialLaminates5,
  SpecialLaminates6,
  SpecialLaminates7,
  SpecialLaminates8,
  SpecialLaminates9,
  SpecialLaminates10,
  SolidSurfaceCover1,
  SolidSurfaceCover2,
  SolidSurfaceCover3,
  SpecialCover1,
  SpecialCover2,
  SpecialCover3,
  SpecialCover4,
  SpecialCover5,
  SpecialCover6,
  SpecialCover7,
  SpecialCover8,
  SpecialCover9,
  SpecialCover10,
  LuxceCover1,
  LuxceCover2,
  LuxceCover3,
  LuxceCover4,
  CompactCover1,
  CompactCover2,
  CompactCover3,
  CompactCover4,
}

module.exports = { productImages }
